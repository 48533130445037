<template>
 <div class="row">
  <div class="col-lg-8">
    <!-- Payment Gateway -->
    <b-modal size="xl" v-model="openPayw">
      <!-- @Subho:Todo: change the propRedirect to TestSeries when we launch test preparation for multiple segments -->
      <Paygw :propModuleType="modName" :propModuleName="modName" :propRedirect="'subject_modules/TESTSERIES_SAT'" :propModuleObjId="tprepEventId" @emitPaymentStatus="emitPaymentStatus" :propSrc="modName"/>
    </b-modal><!-- Payment Gateway -->

    <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
      <div class="iq-card-body">
        <div class="image-block">
          <img v-if="quizDetail && quizDetail.app_image" :src="quizDetail.app_image" class="img-fluid rounded w-100" alt="blog-img" />
        </div>

        <div class="blog-description mt-2">
          <div>
            <h5 class="mb-3 pb-3 border-bottom">
              {{ quizDetail && quizDetail.app_name ? quizDetail && quizDetail.app_name : "" }}
            </h5>
            <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 pull-right" variant="primary" size="sm" @click="productEdit()">
              Edit
            </b-btn>
            <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 pull-right" variant="primary" size="sm" @click="goToGidePrepDashboard()">
              TestSeries Dashboard
            </b-btn>
            <b-btn class="right_button mr-3" variant="primary" size="sm" @click="collectPayment()">
              Pay
            </b-btn>
          </div>

          <div class="row blog-meta d-flex align-items-center mb-3">
            <div v-if="showErrMsg" class="mt-2 ml-2 primary-color">
              {{ showErrMsg }}
              <b-btn class="ml-3" variant="primary" size="sm" @click="UpdateProfile">Update Profile</b-btn>
            </div>
          </div>

          <p v-if="quizDetail && quizDetail.app_description" class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description"></p>
        </div>
      </div>
   </div>
  </div>

  <!-- Upcomming events -->
  <div class="col-lg-4 gide_right_mobile_data">
   <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)" />
   <CareerHomeList v-if="!isMobileDevice" />
  </div>

  <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
   <div v-html="toastMsg">
   </div>
  </b-toast>

  <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle"
   :propAlertDesc="alertDesc" :propOkBtnText="okText" :prophidebutton="true" :propCancelBtnText="cancelText"
   @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()" />
 </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import { Apps } from "../../../FackApi/api/apps.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import Paygw from "../Paygw/Paygw.vue"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "TPrepProduct",
  components: {
    AlertBox,
    EventHomeList,
    CareerHomeList,
    Paygw
  },
  data () {
    return {
      cvTitle: "TestSeries",
      GideProducts: GideProducts,
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "TestSeries",
      showErrMsg: null,
      showUpgradeAlertBox: false,
      alertDesc: "",
      alertTitle: "",
      okText: "Upgrade",
      cancelText: "Not now",
      source: "ONLINE",
      session_state: null,
      quizDetail: null,
      showActionBtns: true,
      app_id: "GIDEPREP_PRODUCT_ID",
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),

      tprepSubsPlanList: [],
      openPayw: false,
      modName: "testseries",
      tprepEventId: ""
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    socialvue.index()
    if (this.$route.params.pdt_id) {
      this.app_id = this.$route.params.pdt_id
    }

    this.appView()
  },
  methods: {
    /**
     * productEdit
     */
    productEdit () {
      window.open(`/quiz_edit/${this.app_id}`, "_blank")
    },
    /**
     * UpdateProfile
     */
    UpdateProfile () {
      window.open(`/user_edit/${this.userData.user_id}`, "_blank")
    },
    /**
     * Get Info
     */
    async appView () {
      try {
        this.quizId = this.app_id
        const quizResp = await Apps.appView(this, this.quizId)
        this.quizDetail = quizResp.resp_data.data
      }
      catch (error) {
        console.error("Exception in appview()", error.message)
      }
    },
    /**
     * collectPayment
     */
    async collectPayment () {
      this.$router.push(`/subscription_plans?subs_plan_type=${this.GideProducts.TESTSERIES}&redirect=${GideProducts.TESTSERIES}`)
    },
    /**
     * goToGidePrepDashboard
     */
    goToGidePrepDashboard () {
      this.$router.push("/testseries_dashboard")
    },
    /**
     * emitPaymentStatus
     */
    emitPaymentStatus () {
    }
  }
}
</script>
